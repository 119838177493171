import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
	{
		path: '',
		redirect: '/shoppinglist/lists'
	},
	{
		path: '/shoppinglist/lists',
		name: 'Lists',
		component: () => import ('@/plugins/lib@shoppinglist/list/list.vue')
	},
	{
		path: '/shoppinglist/list/:id',
		name: 'List detail',
		component: () => import ('@/plugins/lib@shoppinglist/detail/detail.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
