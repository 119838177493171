import wAxios from '@/plugins/w/axios'

export default {
	namespaced: true,

	state: {
		lists: JSON.parse(localStorage.getItem('hl_shoppingListItems') || '[]'),
	},

	mutations: {
		update_lists(state: any, data: any) {
			state.lists = data.lists
			localStorage.setItem('hl_shoppingListItems', JSON.stringify(data.lists))
		}
	},

	actions: {
		loadLists({commit}: {commit: any}) {
			return new Promise((resolve, reject) => {
				wAxios.get_data('v1/shopping-lists')
					.then((response: any) => {
						const lists = response

						commit('update_lists', {
							lists
						})

						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},

		createList({dispatch}: {dispatch: any}, data) {
			return new Promise((resolve, reject) => {
				wAxios.post_data('v1/shopping-lists', data)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},

		updateList({dispatch}: {dispatch: any}, data) {
			return new Promise((resolve, reject) => {
				wAxios.put_data(`v1/shopping-lists/${data.id}`, data)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},

		deleteList({dispatch}: {dispatch: any}, listId) {
			return new Promise((resolve, reject) => {
				wAxios.delete_data(`v1/shopping-lists/${listId}`)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},

		createItem({dispatch}: {dispatch: any}, data) {
			return new Promise((resolve, reject) => {
				wAxios.post_data(`v1/shopping-lists/${data.listId}/items`, data.item)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},

		updateItem({dispatch}: {dispatch: any}, data) {
			return new Promise((resolve, reject) => {
				wAxios.put_data(`v1/shopping-lists/${data.listId}/items/${data.item.id}`, data.item)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},

		deleteItem({dispatch}: {dispatch: any}, data) {
			return new Promise((resolve, reject) => {
				wAxios.delete_data(`v1/shopping-lists/${data.listId}/items/${data.itemId}`)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},

		
	},

	getters: {
		lists: (state: any) =>  {
			return state.lists
		},
		
		list: (state: any, id) => {
			return state.lists.find(list => list.id == id)
		}
	}
}