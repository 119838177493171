
import { basketOutline, albumsOutline, addCircleOutline } from 'ionicons/icons'
import { ref }				from 'vue'
import { useRoute } 		from 'vue-router'
import MockLists			from '@/mocks/list'
import { alertController } 	from '@ionic/vue'
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			basketOutline,
			albumsOutline,
			addCircleOutline,
			selectedIndex: null,
			route: null
		}
	},

	computed: {
		isSelected(url)  {
			return url === this.route.path ? 'selected' : ''
		},

		...mapGetters('shoppingList', [
			'lists'
		]),

		pages() {
			return this.lists.map(list => {
				return {
					title: list.title,
					url: `/shoppinglist/list/${list.id}`,
					iosIcon: basketOutline,
					mdIcon: basketOutline
				}
			})
		}
	},

	async created() {
		await this.$store.dispatch('shoppingList/loadLists')

		this.route 			= useRoute()
		this.selectedIndex 	= ref(0)
	},

	methods: {
		async addNew() {
			const alert = await alertController.create({
				header: 'Enter list name',
				inputs: [{
					name: 'title',
					type: 'text',
					placeholder: 'Family dinner',
				}],
				buttons: [{
					text: 'Cancel',
					role: 'cancel'
				},
				{
					text: 'Create',
					handler: async(value) => {
						await this._createList(value.title)
					}
				}]
			})

			return alert.present()
		},

		async _createList(title) {
			this.$wLoader.startLoading('')

			try {
				const shoppingList = await this.$store.dispatch('shoppingList/createList', {
					title: title.length ? title : 'Unnamed list',
					items: []
				})

				this.$refs.menu.$el.close()
				this.$router.push({name: 'List detail', params: {id: shoppingList.id}})
			} catch (error) {
				console.log(error)
			}

			this.$wLoader.clearLoading()
		}
	}
}
